$(document).ready(function(){
    toastr.options = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": true,
        "progressBar": true,
        "positionClass": "toast-top-right",
        "preventDuplicates": true,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    };

    $('#country').select2({
        placeholder: 'Select Country',
        width: '100%'
    });

    // Register function
    $('#signup-submit').on('click', function (e) {
        e.preventDefault();

        var signupData = {
            name: $('#signup-name').val(),
            username: $('#signup-username').val(),
            email: $('#signup-email').val(),
            password: $('#signup-password').val(),
            confirmPassword: $('#confirm-password').val(),
            phone: $('#phone-no').val(),
            gender: $('#gender').val(),
            address: $('#address').val(),
            city: $('#city').val(),
            state: $('#state').val(),
            country: $('#country').val(),
            pincode: $('#pincode').val()
        };

        // Basic validation
        if (signupData.name === '') {
            toastr.error('Name is required.');
            return;
        }
        if (signupData.username === '') {
            toastr.error('Username is required.');
            return;
        }
        if (signupData.email === '' || !validateEmail(signupData.email)) {
            toastr.error('A valid email is required.');
            return;
        }
        if (signupData.password === '') {
            toastr.error('Password is required.');
            return;
        }
        if (signupData.confirmPassword === '' || signupData.password !== signupData.confirmPassword) {
            toastr.error('Passwords do not match.');
            return;
        }
        if (signupData.phone === '' || signupData.phone.length !== 10) {
            toastr.error('Phone number must be 10 digits.');
            return;
        }
        if (signupData.gender === '') {
            toastr.error('Gender is required.');
            return;
        }
        if (signupData.address === '') {
            toastr.error('Address is required.');
            return;
        }
        if (signupData.city === '') {
            toastr.error('City is required.');
            return;
        }
        if (signupData.state === '') {
            toastr.error('State is required.');
            return;
        }
        if (signupData.country === '') {
            toastr.error('Country is required.');
            return;
        }
        if (signupData.pincode === '' || signupData.pincode.length !== 6) {
            toastr.error('Pincode must be 6 digits.');
            return;
        }

        $.ajax({
            url: '/signup',
            method: 'POST',
            data: signupData,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        })
        .done(function (response) {
            toastr.success('Sign up successful!');
            $('#signup-form')[0].reset();
            setTimeout(function () {
                window.location.href = "/login";
            }, 3000);
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
            toastr.error('Something went wrong! Please try again.');
        });
    });

    function validateEmail(email) {
        var re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return re.test(email);
    }
});
